<template>
    <NuxtLink no-prefetch :to="fullPath" rel="follow" class="blog-basic-card">
        <picture class="image">
            <img :src="post.image.url" :alt="post.title" :title="post.title" width="12" height="12" />
        </picture>

        <div class="wrapper-info">
            <div class="title-and-description">
                <h3 class="title">{{ post.title }}</h3>
                <p class="description" v-html="content"></p>
            </div>
            <div class="footer">
                <p class="views">
                    <img :src="$assets.gray.eye" width="12" height="12" alt="Icono visualizaciones" />
                    {{ post.reading_time }} {{ $lang.components.cardBlog.minutes }}
                </p>
                <p class="likes">
                    <img :src="$assets.gray.heart" width="12" height="12" alt="Icono me gusta" />
                    {{ post.likes_count }} {{ $lang.components.cardBlog.like }}
                </p>
            </div>
        </div>
    </NuxtLink>
</template>

<script lang="ts">
export default defineComponent({
    name: 'CardBlog',
    props: {
        post: {
            type: Object,
            required: true,
        },
        category: {
            type: String,
        },
    },
    computed: {
        content(): string {
            return this.post.content.replace(/<[^>]*>?/gm, ' ') || ''
        },
        fullPath(): string {
            return '/blog/' + this.category + '/' + this.post.slug
        },
    },
})
</script>

<style lang="postcss" scoped>
.blog-basic-card {
    @apply flex min-h-full cursor-pointer flex-col items-center rounded-xl bg-white text-sm lg:hover:outline lg:hover:outline-1 lg:hover:outline-gray-300;
    .image {
        @apply block h-52 w-full;
        img {
            @apply h-full w-full rounded-t-xl object-cover;
        }
    }
    .wrapper-info {
        @apply flex flex-1 flex-col justify-between space-y-1.5 p-2;
        .title-and-description {
            @apply space-y-1.5;
            .title {
                @apply line-clamp-2 font-semibold;
            }
            .description {
                @apply line-clamp-2 text-sm text-gray-800;
            }
        }

        .footer {
            @apply flex items-end gap-3 text-sm text-gray-800;
            .views {
                @apply flex items-center gap-1;
                img {
                    @apply h-3;
                }
            }
            .likes {
                @apply flex items-center gap-1;
                img {
                    @apply h-4;
                }
            }
        }
    }
}
</style>
